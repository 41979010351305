<template>
    <!-- form modal -->

    <div
        v-if="showSendReceiptModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showSendReceiptModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Send Receipt ({{ receiptReference }})</div>
                <div>
                    <router-link to="#">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>
            <form class="entity-form" @submit.prevent="onSubmit">
                <div class="pa3">
                    <div class="flex flex-wrap justify-between">
                        <!-- Product name -->
                        <div class="pt2 w-100 w-100-l pr2-l">
                            <div class="pv2 font-w1">
                                <label for="name">Name</label>
                            </div>
                            <input
                                type="text"
                                placeholder="Receiver name"
                                id="name"
                                v-model="state.name"
                                required
                            />
                        </div>

                        <div class="pt2 w-100 w-100-l pr2-l">
                            <div class="pv2 font-w1">
                                <label for="email">Email</label>
                            </div>
                            <input
                                type="text"
                                placeholder="Receiver email"
                                id="email"
                                v-model="state.email"
                                required
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="flex flex-wrap justify-end" style="gap: 1rem">
                        <button class="mt1" @click.prevent="closeModal" :disabled="state.disableSubmitButton">Cancel</button>
                        <button class="mt1" type="submit" :disabled="state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Processing...' : 'Submit' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'SendReceiptModal',
    components: { },
    props: {
        closeModal: Function
    },

    setup(props) {
        const modalRef = ref(null)
        const store = useStore()
        const showSendReceiptModal = computed(() => store.state?.Transaction?.showSendReceiptModal)
        const receiptReference = computed(() => store.state?.Transaction?.receiptReference)

        const initialState = {
            name: '',
            email: '',
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        const closeModal = () => {
            props.closeModal()
            state.name = ''
            state.email = ''
        }

        const onSubmit = () => {
            state.disableSubmitButton = true
            const payload = {
                reference: receiptReference.value,
                name: state.name,
                email: state.email
            }

            store.dispatch('Transaction/sendReceipt', payload).finally(() => {
                state.disableSubmitButton = false
                closeModal()
            })
            .catch(error => {
                state.disableSubmitButton = false
                console.error(error)
            })
        }

        const handleOnClickOutsideModal = e => {
            if (modalRef?.value && !modalRef?.value.contains(e.target)) {
                closeModal()
            }
        }

        return {
            state,
            onSubmit,
            showSendReceiptModal,
            modalRef,
            handleOnClickOutsideModal,
            receiptReference,
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>
