<template>
    <!-- form modal -->

    <div
        v-if="showEditTransactionModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showEditTransactionModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Edit Transaction ({{ transaction?.number || transaction.reference }})</div>
                <div>
                    <router-link to="#">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>
            <form class="entity-form" @submit.prevent="onSubmit">
                <div class="pa3">
                    <div class="flex flex-wrap justify-between">
                        <!-- Product name -->
                        <div class="pt2 w-100 w-100-l pr2-l">
                            <div class="pv2 font-w1">
                                <label for="date">Date</label>
                            </div>
                            <input
                                type="text"
                                placeholder="Transaction date"
                                id="date"
                                v-model="state.date"
                                required
                            />
                        </div>

                        <div class="pt2 w-100 w-100-l pr2-l">
                            <div class="pv2 font-w1">
                                <label for="description">Description</label>
                            </div>
                            <input
                                type="text"
                                placeholder="Description"
                                id="description"
                                v-model="state.description"
                            />
                        </div>
                        <div class="pt2 w-100 w-100-l pr2-l">
                            <Customer @on-update-data="updateEntity" />
                        </div>
                    </div>
                    <hr />
                    <div class="flex flex-wrap justify-end" style="gap: 1rem">
                        <button class="mt1" @click.prevent="closeModal" :disabled="state.disableSubmitButton">Cancel</button>
                        <button class="mt1" type="submit" :disabled="state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Processing...' : 'Update' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
  import Customer from '@/components/Customer'

export default {
    name: 'EditTransactionModal',
    components: {Customer},
    props: {
        closeModal: Function
    },

    setup(props) {
        const modalRef = ref(null)
        const store = useStore()
        const showEditTransactionModal = computed(() => store.state?.Transaction?.showEditTransactionModal)
        const transaction = computed(() => store.state?.Transaction?.transaction)

        const initialState = {
            date: '',
            entity: '',
            entityId: '',
            customer: null,
            description: '',
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        watch(() => transaction.value, (currValue, prevValue) => {
            if (currValue !== prevValue) {
                state.date = moment(currValue?.date).format('YYYY-MM-DD')
                if (currValue?.entityName && currValue?.entityName !== "Unknown") {
                    state.entityId = currValue?.entity || ''
                    state.entity = currValue?.entityName || ''
                    store.dispatch('Entity/getEntity', { id: currValue?.entity }).then((resp) => {
                        
                    })
                }
                state.description = currValue?.description || ''
            }
        })

        const updateEntity = (value) => {
            const entityFullName = (value?.name)
                ? `${value?.name || ''}`
                : `${value?.firstName || ''} ${value?.lastName || ''}`
            state.entity = entityFullName
            state.entityId = value?.id || ''
        }

        const closeModal = () => {
            props.closeModal()
            state.date = ''
            state.entity = ''
            state.entityId = ''
            state.description = ''
        }

        const onSubmit = () => {
            state.disableSubmitButton = true
            const payload = {
                date: state.date,
                entityName: state.entity,
                entity: state.entityId,
                description: state.description,
            }

            store.dispatch('Transaction/editTransaction', {
                id: transaction.value._id,
                data: payload
            }).finally(() => {
                state.disableSubmitButton = false
                closeModal()
            })
            .catch(error => {
                state.disableSubmitButton = false
                console.error(error)
            })
        }

        const handleOnClickOutsideModal = e => {
            if (modalRef?.value && !modalRef?.value.contains(e.target)) {
                // closeModal()
            }
        }

        return {
            state,
            onSubmit,
            showEditTransactionModal,
            modalRef,
            handleOnClickOutsideModal,
            transaction,
            updateEntity,
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>
