<template>
    <div class="w-100">
        <div v-if="customer.id" class="flex justify-between items-center box-border-bottom pa2">
            <div class="flex items-center">
                <div>
                    <div class="org-img-placeholder">
                        <span>{{ entityInitials }}</span>
                    </div>
                </div>
                <div class="pl3">
                    <div class="font-w1">{{ customer.name }}</div>
                    <div>{{ customer.email }}</div>
                </div>
            </div>
            <div class="flex items-center">
                <div>Customer</div>
                <a href="#" @click.prevent="removeEntity">
                    <img src="../assets/images/trash.png" class="ph2" alt="" />
                </a>
            </div>
        </div>
        <div v-else class="flex justify-between items-center searchContainer2 box-border unZIndexx" style="position:relative;">
            <img src="../assets/images/search.svg" alt="" />
            <input
                autocomplete="off"
                class="searchBox pl2 w-100"
                type="text"
                name="entity"
                style="border: unset; box-shadow: unset"
                placeholder="Search for customer with name, phone number or email"
                id="entity"
                @input="element => handleEntitySearchOnChange(element.target.value)"
                @focusout="
                    () => {
                        showDropDownOnSelectEntity(false)
                        handleIsRelative(false)
                    }
                "
                @focusin="
                    () => {
                        showDropDownOnSelectEntity(true)
                        handleIsRelative(true)
                    }
                "
                v-model="state.entity"
            />
            <a
                v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(30)"
                href=""
                @click.prevent="() => toggleCreateEntity(true)"
                title="Create new customer"
            >
                <img src="../assets/images/circle-plus-solid.svg" alt="" style="width:18px;" />
            </a>
            <template v-if="state.entityDropDown && state.entity?.length">
                <div
                    style="
                        position: absolute;
                        left: 0;
                        top: 100%;
                        height: auto;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        box-shadow: 0px 1px 4px 0px #2b59ff;
                        background: #132c8c;
                        z-index: 100;
                        color: #fff;
                    "
                    class="bg-white flex flex-column items-start"
                >
                    <div class="mt2" style="width: 100%; max-height: 250px; overflow-y: scroll" v-if="EntitySearchedList.length">
                        <ul class="w-100">
                            <li
                                v-for="ent of EntitySearchedList"
                                v-bind:key="ent._id"
                                class="pointer hover-bg-black-10 pa2 db"
                                style="width: 100%"
                                @click.prevent="handleEntityClickFromSearch(ent)"
                            >
                                <span v-if="ent?.name">
                                    {{ `${ent?.name || ''}` }}
                                </span>
                                <span v-else>
                                    <div>{{ `${ent?.firstName || ''} ${ent?.lastName || ''}` }}</div>
                                    <div v-if="ent?.email" class="text-muted">{{ ent?.email }}</div>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </div>
        <create-entity :closeModal="() => toggleCreateEntity()" @on-created="entityCreated" />
    </div>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import CreateEntity from '@/components/CreateEntity'

export default {
    name: 'PosCustomer',
    components: { CreateEntity },
    props: {
        entity: Object,
        closeModal: Function
    },

    setup(props, { emit }) {
        const store = useStore()
        const incomingEntityId = computed(() => store.state.Entity?.incomingEntityId)
        const EntitySearchedList = computed(() => store.state.Entity?.entityListFromSearch)
        const entityInitials = computed(() => store.state.Entity?.entityInitials)
        const customer = computed(() => store.state?.Pos?.customer)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const initialState = () => ({
            customer: {
                id: props.entity?._id || '',
                name: props.entity?.name || '',
                email: props.entity?.email || '',
                phone: props.entity?.phone || '',
                address: props.entity?.address || '',
                city: props.entity?.city || '',
                state: props.entity?.state || '',
                zip: props.entity?.zip || '',
                country: props.entity?.country || '',
                vat_number: props.entity?.vat_number || '',
                tax_number: props.entity?.tax_number || '',
                website: props.entity?.website || ''
            },
            entity: '',
            entityName: '',
            entityDropDown: false,
            isRelative: false,
            entityId: ''
        })

        let state = reactive(initialState())
        let timeOut = null

        const setEntityInitials = name => {
            const payload = { entityDetails: { fullName: name } }
            store.dispatch('Entity/setEntityInitials', payload)
        }

        const setEntityData = value => {
            const entityFullName = (value?.name)
                ? `${value?.name || ''}`
                : `${value?.firstName || ''} ${value?.lastName || ''}`
            state.entity = entityFullName

            store.dispatch('Pos/setCustomer', value)
            if (entityFullName !== '') setEntityInitials(entityFullName)

            state.customer.id = value._id
            state.customer.name = entityFullName
            state.customer.email = value.email
            state.customer.phone = value.phone
            state.customer.address = value.address
            state.customer.city = value.city
            state.customer.state = value.state
            state.customer.country = value.country
            state.customer.vat_number = value.vat_number
            state.customer.tax_number = value.tax_number
            state.customer.website = value.website
        }

        const updateData = () => {
            emit('onUpdateData', state.customer)
        }

        const handleEntitySearchOnChange = query => {
            const entityName = query
            const name = entityName.trim().split(' ')
            const firstName = name[0]
            const lastName = entityName.replace(new RegExp(firstName, 'g'), '').trim()

            setEntityData({
                _id: '',
                firstName,
                lastName,
                email: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                vat_number: '',
                tax_number: '',
                website: ''
            })
            state.entity = query
            state.customer.name = query
            clearTimeout(timeOut)
            if (query.trim().length) {
                timeOut = setTimeout(() => {
                    store.dispatch('Entity/getEntityEntryBySearch', { query })
                }, 300)
            }
        }

        const showDropDownOnSelectEntity = (dropDownState = false) => {
            if (!dropDownState) {
                return setTimeout(() => {
                    state.entityDropDown = dropDownState
                }, 300)
            }

            state.entityDropDown = dropDownState
        }

        const handleEntityClickFromSearch = value => {
            if (value?.name) {
                state.entity = `${value?.name || ''}`
            } else {
                state.entity = `${value?.firstName || ''} ${value?.lastName || ''}`
            }

            state.entityName = value._id

            // update invoice customer
            if (value._id) {
                setEntityData(value)
                updateData()
            }

            store.dispatch('Entity/setEntityIdFromExistingEntities', value._id).then(resp => {
                if (resp) onChangeEntityId()
            })
            showDropDownOnSelectEntity(false)
        }

        const onChangeEntityId = () => {
            state.entityId = incomingEntityId.value
        }

        const handleIsRelative = (value = false) => {
            setTimeout(() => {
                state.isRelative = value
            }, 300)
        }

        const toggleCreateEntity = payload => {
            store.dispatch('Entity/toggleCreateEntity', payload)
            if (payload && state.customer.name) store.dispatch('Entity/setEntityName', state.entity)
        }

        const removeEntity = () => {
            setEntityData(state.customer)
            updateData()
        }

        const entityCreated = entity => {
            handleEntityClickFromSearch(entity)
            setEntityData(entity)
        }

        watch(
            () => incomingEntityId.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.entityId = incomingEntityId.value
                }
            }
        )

        return {
            state,
            customer,
            handleEntitySearchOnChange,
            showDropDownOnSelectEntity,
            handleEntityClickFromSearch,
            handleIsRelative,
            EntitySearchedList,
            entityInitials,
            toggleCreateEntity,
            removeEntity,
            entityCreated,
            rolePermissions,
            role
        }
    }
}
</script>
<style scoped>
.searchContainer2 {
    overflow: unset;
    background-color: #fff;
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
</style>
